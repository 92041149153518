<template>
  <AView
    :value="value"
    :actions="actions"
    :name="proposal && proposal.name ? proposal.name : undefined"
    :payload="{
      from: value,
      proposalId: proposalId,
      leadId: leadId,
    }"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    class="proposal-assistant-container"
  >
    <template v-slot:content="{}">
      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <!-- Main Form -->

          <v-row>
            <v-slide-y-reverse-transition>
              <v-col cols="12" v-if="tasks && tasks.length">
                <VueHTMLGantt
                  :name="proposal && proposal.name ? proposal.name : undefined"
                  v-model="tasks"
                  :from="from"
                  :to="to"
                  :min="24"
                  @input="onDurationChange"
                >
                  <template v-slot:tasks="{ tasks, id }">
                    <draggable
                      class="list-group"
                      tag="ul"
                      :value="tasks"
                      v-bind="dragOptions"
                      @start="isDragging = true"
                      @end="isDragging = false"
                      @input="onTaskOrderChanged"
                    >
                      <transition-group
                        type="transition"
                        :name="!isDragging ? 'flip-list' : null"
                      >
                        <li
                          class="list-group-item"
                          v-for="(task, i) in tasks"
                          :key="i"
                          :id="id(task.id)"
                        >
                          <ServiceGanttPreview
                            :key="task.id"
                            class="my-2"
                            :value="task.origin.service"
                            @onRemove="onTaskRemove(i)"
                            @onPlanSelected="onPlanSelected(task)"
                          ></ServiceGanttPreview>

                          <draggable
                            v-if="task.tasks && task.tasks.length"
                            class="list-group deliverables"
                            :group="task.id"
                            tag="ul"
                            :value="task.tasks"
                            v-bind="dragOptions"
                            @start="isDragging = true"
                            @end="isDragging = false"
                            @input="
                              (val) => onDeliverableOrderChanged(task, val)
                            "
                          >
                            <transition-group
                              type="transition"
                              :name="!isDragging ? 'flip-list' : null"
                            >
                              <li
                                class="list-group-item"
                                v-for="(subTask, i) in task.tasks"
                                :key="i"
                                :id="id(subTask.id)"
                              >
                                <DeliverableGanttPreview
                                  :key="subTask.id"
                                  class="my-1"
                                  :value="subTask.origin.deliverable"
                                  @onRemove="onTaskRemove(i)"
                                ></DeliverableGanttPreview>
                              </li>
                            </transition-group>
                          </draggable>
                        </li>
                      </transition-group>
                    </draggable>
                    <v-card
                      class="bg-grey-gradient d-flex flex-column justify-center align-center"
                    >
                      <v-btn color="primary" icon>
                        <v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-card>
                  </template>

                  <template v-slot:track="{ track }">
                    <ServiceGanttTrack
                      v-if="track.origin.service"
                      :from="track.from"
                      :to="track.to"
                      :value="track.origin.service"
                      :width="track.style.width"
                    ></ServiceGanttTrack>
                    <DeliverableGanttTrack
                      v-if="track.origin.deliverable"
                      :from="track.from"
                      :to="track.to"
                      :value="track.origin.deliverable"
                      :width="track.style.width"
                    ></DeliverableGanttTrack>
                  </template>
                </VueHTMLGantt>
              </v-col>
              <v-col cols="12" v-else>
                <v-alert prominent text type="info">
                  Please attach at least one service to create a new proposal.
                  It will be automatically attached to the lead and added to
                  history.
                </v-alert>
              </v-col>
            </v-slide-y-reverse-transition>
            <PageNavigationRight
              v-model="isNavigationOpen"
              :buttons="navigationButtons"
              :hideOnClickOutside="false"
              @onHideEditor="isNavigationOpen = true"
              @onShowEditor="isNavigationOpen = false"
              @onConfig="
                () => {
                  isNavigationOpen = true;
                  tab = 1;
                }
              "
              @onAttachments="
                () => {
                  isNavigationOpen = true;
                  tab = 0;
                }
              "
            >
              <template v-slot:content>
                <v-tabs-items v-model="tab" class="transparent">
                  <v-tab-item>
                    <SelectorServices
                      dark
                      autoload
                      label="Services"
                      helpText="You can select a set of Services to include into Proposal"
                      return-object
                      v-model="recommended.services"
                      multiple
                    ></SelectorServices>
                    <v-btn
                      v-if="proposalId"
                      dark
                      color="primary"
                      :disabled="
                        !(recommended.services && recommended.services.length)
                      "
                      @click="attachServices"
                    >
                      Add to Proposal
                    </v-btn>

                    <v-btn
                      v-else
                      dark
                      color="primary"
                      :disabled="
                        !(recommended.services && recommended.services.length)
                      "
                      @click="onProposalCreate"
                    >
                      Create Proposal
                    </v-btn>
                  </v-tab-item>
                  <v-tab-item>
                    <HelpFormInput
                      dark
                      v-model="proposal.name"
                      label="Proposal Name"
                      :helpText="'You can specify a custom name to make this proposal more recognizable.'"
                      @input="onProposalUpdate"
                      required
                    ></HelpFormInput>
                    <v-date-picker
                      dark
                      class="pa-2"
                      background-color="transparent"
                      header-color="primary"
                      color="primary"
                      event-color="primary"
                      ref="picker"
                      :value="startDate"
                      :min="today"
                      @change="onStartDateChange"
                      full-width
                    ></v-date-picker>
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </PageNavigationRight>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </AView>
</template>
        
        
        
<script>
import { mapState } from "vuex";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
// import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import { PRESENT_MASTER_BUTTONS } from "../constants/actions.gh";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
// import ProposalAssistantForm from "../components/forms/ProposalAssistantForm.vue";
import SelectorServices from "../../../../../components/wad/atoms/common/autocompletes/SelectorServices.vue";
import moment from "moment";
import VueHTMLGantt from "../components/lib/VueHTMLGantt.vue";
import ServiceGanttPreview from "../components/preview/ServiceGanttPreview.vue";
import ServiceGanttTrack from "../components/preview/ServiceGanttTrack.vue";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import DeliverableGanttPreview from "../components/preview/DeliverableGanttPreview.vue";
import DeliverableGanttTrack from "../components/preview/DeliverableGanttTrack.vue";
import PageNavigationRight from "../../../../../components/wad/molecules/layout/PageNavigationRight.vue";
import { GUI } from "../../../GrapUI.config";
import HelpFormInput from "../../../../../components/wad/atoms/common/inputs/HelpFormInput.vue";

import _ from "lodash";

export default {
  name: "ProposalAssistant",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    // FormSection,
    // ProposalAssistantForm,
    SelectorServices,
    VueHTMLGantt,
    ServiceGanttPreview,
    ServiceGanttTrack,
    draggable,
    DeliverableGanttPreview,
    DeliverableGanttTrack,
    PageNavigationRight,
    HelpFormInput,
  },
  computed: {
    ...mapState("DocAssistantStore", []),
    ...mapState("ProposalsStore", ["proposals"]),

    actions: function () {
      return PermissionsHelper.getActions(
        [PRESENT_MASTER_BUTTONS.MAIN],
        this.value.permissions
      );
    },
    startDate: function () {
      // return undefined
      return this.proposal && this.proposal.start_date
        ? moment(this.proposal.start_date).format("YYYY-MM-DD")
        : moment(this.from).add(1, "day").startOf("day").format("YYYY-MM-DD");
    },
  },
  async mounted() {
    if (this.proposalId) {
      this.proposalWatcher();
      await this.$store.dispatch("ProposalsStore/GetProposal", {
        id: this.proposalId,
        refresh: true,
      });
    } else if (this.leadId)
      this.recommended = await this.$store.dispatch(
        "LeadStore/getLeadRecommended",
        {
          id: this.leadId,
          refresh: true,
        }
      );
  },
  beforeDestroy() {
    if (this.watcher) this.watcher();
  },
  data() {
    const leadId =
      this.value.relation &&
      this.value.relation.params &&
      this.value.relation.params.leadId
        ? this.value.relation.params.leadId
        : undefined;

    const proposalId =
      this.value.relation &&
      this.value.relation.params &&
      this.value.relation.params.proposalId
        ? this.value.relation.params.proposalId
        : undefined;

    return {
      leadId,
      proposalId,
      proposal: {},
      recommended: {
        services: [],
      },
      watcher: undefined,

      isDragging: false,
      delayedDragging: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },

      from: moment().startOf("day").toISOString(),
      to: moment().startOf("day").add(2, "months").toISOString(),
      today: moment().toISOString(),

      //   Gantt Chart
      tasks: [],

      // Navigation
      tab: 0,
      isNavigationOpen: proposalId ? false : true,
      navigationButtons: [
        {
          name: "Expand Config",
          action: "onExpand",
          openIcon: "mdi-chevron-right",
          closeIcon: "mdi-plus",
        },
        {
          name: "Attachments",
          action: "onAttachments",
          openIcon: "mdi-paperclip-plus",
          closeIcon: "mdi-paperclip-plus",
        },
        {
          name: "Export Config",
          action: "onConfig",
          openIcon: "mdi-cog-outline",
          closeIcon: "mdi-cog-outline",
        },
      ],
    };
  },
  methods: {
    onStartDateChange(date) {
      const previousStart =
        this.proposal && this.proposal.start_date
          ? moment(this.proposal.start_date)
          : moment(this.from).add(1, "day").startOf("day");

      const diff = moment(date).diff(previousStart, "days");

      this.tasks.forEach((task) => {
        const [firstTrack] = task.tracks;

        firstTrack.from = moment(firstTrack.from)
          .add(diff, "days")
          .toISOString();
        firstTrack.to = moment(firstTrack.to).add(diff, "days").toISOString();

        if (task.tasks && task.tasks.length)
          task.tasks.forEach((subTask) => {
            subTask.tracks.forEach((subTaskTrack) => {
              subTaskTrack.from = moment(subTaskTrack.from)
                .add(diff, "days")
                .toISOString();
              subTaskTrack.to = moment(subTaskTrack.to)
                .add(diff, "days")
                .toISOString();
            });
          });
      });

      this.$set(this.proposal, "start_date", moment(date).toISOString());

      this.onProposalUpdate();
    },
    proposalWatcher() {
      if (!this.watcher && this.proposalId)
        this.watcher = this.$watch(
          `proposals.${this.proposalId}`,
          (newVal) => {
            console.log("HTMMM????????", newVal);
            this.proposal = JSON.parse(JSON.stringify(newVal));

            this.tasks = this.toTasks(this.proposal);
          },
          {
            deep: true,
          }
        );
    },
    onTaskOrderChanged(newVal) {
      this.tasks = newVal.map((val) => val.origin);
      this.onProposalUpdate();
    },
    onDeliverableOrderChanged(task, newVal) {
      task.origin.tasks = newVal.map((val) => val.origin);
      this.onProposalUpdate();
    },
    onTaskRemove(i) {
      this.tasks.splice(i, 1);

      this.onProposalUpdate();
    },
    async onProposalCreate() {
      // attach services and draw gantt
      this.attachServices({ ignoreUpdate: true });

      const newProposal = this.toProposal();

      const proposal = await this.$store.dispatch(
        "ProposalsStore/CreateNewProposal",
        { newProposal }
      );

      this.proposal = JSON.parse(JSON.stringify(proposal));
      this.proposalId = this.proposal.id;
      this.tasks = this.toTasks(this.proposal);

      this.value.relation.params.proposalId = this.proposal.id;
      GUI.store.update(this.value.relation);

      this.$nextTick(() => {
        this.proposalWatcher();
      });
    },
    onDurationChange() {
      const proposalToUpdate = this.toProposal();

      console.log("On Duration Change");

      this.$store.dispatch("ProposalsStore/UpdateProposal", {
        proposal: proposalToUpdate,
        refresh: false,
      });
    },
    onProposalUpdate() {
      const proposalToUpdate = this.toProposal();

      this.$store.dispatch("ProposalsStore/UpdateProposal", {
        proposal: proposalToUpdate,
      });
    },
    onPlanSelected(task) {
      const from =
        this.proposal && this.proposal.start_date
          ? moment(this.proposal.start_date).toISOString()
          : moment(this.from).add(1, "day").startOf("day").toISOString();

      let maxTo =
        task.origin.service.selectedPlan &&
        task.origin.service.selectedPlan.duration
          ? moment(from)
              .add(task.origin.service.selectedPlan.duration, "week")
              .startOf("day")
              .toISOString()
          : moment().add(2, "weeks").startOf("day").toISOString();

      console.log("start: ", this.proposal.start_date);
      console.log("maxTo: ", maxTo);

      console.log("from: ", from);

      //   TODO refactor this part
      this.$set(
        task.origin,
        "tasks",
        task.origin.service.selectedPlan
          ? task.origin.service.selectedPlan.packageItems.map((pI) => {
              //avg_time in minutes

              const tracks =
                pI.value_measure === "quantity" &&
                pI.deliverable.activity &&
                pI.deliverable.activity.avg_time
                  ? (() => {
                      const to = moment(from).add(
                        pI.deliverable.activity.avg_time,
                        "minutes"
                      );

                      maxTo = moment(maxTo).isBefore(to)
                        ? to.toISOString()
                        : maxTo;

                      return [
                        {
                          id: uuid(),
                          name: `${pI.deliverable.name}`,
                          deliverable: pI.deliverable,
                          from: from,
                          to: to.toISOString(),
                        },
                      ];
                    })()
                  : pI.value_measure === "minutes" &&
                    pI.deliverable.activity &&
                    pI.deliverable.activity.avg_time
                  ? new Array(
                      Math.round(pI.value / pI.deliverable.activity.avg_time)
                    )
                      .fill(undefined)
                      .map((el, i) => {
                        const to = moment(from)
                          .add(i, "day")
                          .startOf("day")
                          .add(pI.deliverable.activity.avg_time, "minutes");

                        maxTo = moment(maxTo).isBefore(to)
                          ? moment(to).endOf("day").toISOString()
                          : maxTo;

                        return {
                          id: uuid(),
                          name: `${pI.deliverable.name}`,
                          deliverable: pI.deliverable,
                          from: moment(from)
                            .add(i, "day")
                            .startOf("day")
                            .toISOString(),
                          to: to.toISOString(),
                        };
                      })
                  : [
                      {
                        id: uuid(),
                        name: `${pI.deliverable.name}`,
                        deliverable: pI.deliverable,
                        from: moment(from).startOf("day").toISOString(),
                        to: maxTo,
                      },
                    ];

              return {
                id: uuid(),
                name: `${pI.deliverable.name}`,
                tracks,
                deliverable: pI.deliverable,
              };
            })
          : undefined
      );

      //   TODO, make it better
      task.origin.tracks[0].to = maxTo;
      task.origin.tracks[0].from = from;

      this.onProposalUpdate();
    },
    attachServices({ ignoreUpdate = false } = {}) {
      this.recommended.services.forEach((service) =>
        this.tasks.push({
          id: uuid(),
          name: `${service.code} - ${service.name}`,
          service: { ...service },
          tasks: service.selectedPlan
            ? service.selectedPlan.packageItems.map((pI) => ({
                id: uuid(),
                name: `${pI.deliverable.name}`,
                tracks: [
                  {
                    id: uuid(),
                    name: `${pI.deliverable.name}`,
                    style: {},
                    deliverable: pI.deliverable,
                    from: moment().add(1, "day").startOf("day").toISOString(),
                    to: moment().add(3, "day").startOf("day").toISOString(),
                  },
                ],
                deliverable: pI.deliverable,
              }))
            : undefined,
          tracks: [
            {
              id: uuid(),
              name: `${service.code} - ${service.name}`,
              style: {},
              service,
              from: moment().add(1, "day").startOf("day").toISOString(),
              to: moment().add(3, "day").startOf("day").toISOString(),
            },
          ],
        })
      );

      this.recommended.services = [];

      if (!ignoreUpdate) this.onProposalUpdate();
    },
    toProposal() {
      const items = this.tasks.map((task, i) => {
        const [firstTrack] = task.tracks;

        let subI = 0;

        return {
          id: task.originalId ? task.originalId : undefined,
          proposalService: task.service
            ? {
                id: firstTrack.originalId ? firstTrack.originalId : undefined,
                order: i,
                service_id: task.service.id,
                color: task.service.customColor
                  ? task.service.customColor
                  : undefined,
                from: firstTrack.from,
                to: firstTrack.to,
                selected_plan_id: task.service.selectedPlan
                  ? task.service.selectedPlan.id
                  : undefined,

                deliverables:
                  task.tasks && task.tasks.length
                    ? task.tasks.reduce((acc, subTask) => {
                        const items = subTask.tracks.map((subTrack) => {
                          subI++;
                          return {
                            id: subTrack.originalId
                              ? subTrack.originalId
                              : undefined,
                            order: subI,
                            deliverable_id: subTask.deliverable.id,
                            color: subTask.deliverable.customColor
                              ? subTask.deliverable.customColor
                              : undefined,
                            from: subTrack.from,
                            to: subTrack.to,
                          };
                        });

                        return acc.concat(...items);
                      }, [])
                    : undefined,
              }
            : undefined,
        };
      });

      return {
        ...this.proposal,
        start_date:
          this.proposal && this.proposal.start_date
            ? this.proposal.start_date
            : moment(this.from).add(1, "day").startOf("day").toISOString(),
        lead_id: this.leadId ? this.leadId : undefined,
        items,
      };
    },
    toTasks(proposal) {
      let from =
        this.proposal && this.proposal.start_date
          ? moment(this.proposal.start_date)
          : moment(this.from);

      const output = proposal.items.map((item) => {
        const { proposalService } = item;

        const name = `${proposalService.service.code} - ${proposalService.service.name}`;

        from = moment(proposalService.from).isBefore(from)
          ? moment(proposalService.from).subtract(1, "day")
          : from;

        return {
          id: uuid(),
          originalId: item.id,
          name,
          service: {
            ...proposalService.service,
            selectedPlan: proposalService.selectedPlan,
          },
          selectedPlan: proposalService.selectedPlan,
          tasks: _.chain(proposalService.deliverables)
            .groupBy("deliverable_id")
            .sortBy((value) => value[0].order)
            .map((value) => {
              const deliverable = {
                ...value[0].deliverable,
                customColor: value[0].color ? value[0].color : undefined,
              };

              return {
                id: uuid(),
                name: `${value[0].deliverable.name}`,
                tracks: value.map((proposalDeliverable) => {
                  from = moment(proposalDeliverable.from).isBefore(from)
                    ? moment(proposalDeliverable.from).subtract(1, "day")
                    : from;

                  return {
                    id: uuid(),
                    name: `${proposalDeliverable.deliverable.name}`,
                    customColor: proposalDeliverable.color
                      ? proposalDeliverable.color
                      : undefined,
                    style: {},
                    originalId: proposalDeliverable.id,
                    deliverable,
                    from: proposalDeliverable.from,
                    to: proposalDeliverable.to,
                  };
                }),
                deliverable,
              };
            })
            .value(),
          tracks: [
            {
              id: uuid(),
              originalId: proposalService.id,
              name,
              style: {},
              service: proposalService.service,
              from: proposalService.from,
              to: proposalService.to,
            },
          ],
        };
      });

      this.from = moment(from.toISOString()).subtract(1, "day").toISOString();

      console.log("to TASKS: ", output);
      return output;
    },
  },
};
</script>
        
        
        
<style scoped lang="scss" >
.flip-list-enter, .flip-list-leave-to
/* .card-leave-active for <2.1.8 */ {
  opacity: 0;
  // transform: scale(0);
  opacity: 0;
  transition: none;
}
.flip-list-enter-to {
  transition: none;
}

.flip-list-leave-active {
  /*position: absolute;*/
}

.flip-list-move {
}
.no-move {
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
  list-style: none;
  padding: 0px;
  &.deliverables {
    display: flex;
    justify-content: flex-end;
  }
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.proposal-assistant-container {
  container-type: inline-size;
}

.scroll-row {
}
</style>