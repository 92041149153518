<template>
  <v-card
    :loading="loading"
    class="deliverable-item bg-grey-gradient"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <!-- <div class="deliverable-type-img control" :style="getImageStyle()"></div> -->

    <!-- <div
        class="deliverable-status"
        :class="{
          primary: value.status.code === 'PUBLISHED',
          'teal darken-1': value.status.code === 'PENDING',
        }"
      >
        {{ value.status.name }}
      </div> -->
    <v-card-title class="py-2 deliverable-title">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-icon :size="18" class="mr-2" v-if="value.icon"> {{ value.icon }}</v-icon>
            <span v-else>-</span>
            <span class="mt-6">
              {{ cutFormatter(value.name, 22) }}
            </span>
          </span>
        </template>
        <span>{{ value.name }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="color-preview"
            v-bind="attrs"
            v-on="on"
            :style="{
              background: value.customColor ? value.customColor : undefined,
            }"
          ></div>
        </template>

        <v-card dark>
          <v-color-picker
            @input="colorSelected"
            class="ma-2"
            show-swatches
          ></v-color-picker>
        </v-card>
      </v-menu>
      <!-- <v-btn icon color="error" @click="$emit('onRemove', value)"
          ><v-icon>mdi-delete</v-icon></v-btn
        > -->
    </v-card-title>
    <!-- <v-divider></v-divider> -->
    <!-- <v-card-text class="deliverable-task-content pa-0">
        <v-row>
          <v-col class="flex-grow-1 flex-shrink-1">
            <NumberInput></NumberInput>
          </v-col>
          <v-col class="form-col flex-grow-1 flex-shrink-1">
            <SelectorDefault
              dark
              v-model="value.selectedPlan"
              label="Plan Size"
              :items="value.plans"
              :helpText="'Please specify a plan that uses for the proposal'"
              required
              clearable
              hide-details
              return-object
            ></SelectorDefault
          ></v-col>
        </v-row>
      </v-card-text> -->

    <!-- <div class="card-actions-bottom">
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="$emit('onRemove', value)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
            <v-btn icon @click="$emit('onServiceClick', value)"
              ><v-icon>mdi-share</v-icon></v-btn
            >
          </v-card-actions>
        </div> -->
  </v-card>
</template>
    
    <script>
import { FormatterHelper } from "../../../../../../components/helpers/formatter.helper";
//   import NumberInput from "../../../../../../components/wad/atoms/common/NumberInput.vue";
//   import SelectorDefault from "../../../../../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      cutFormatter: FormatterHelper.cutString,
      loading: false,
      menu: false,
    };
  },
  components: {
    //   SelectorDefault,
    //   NumberInput,
  },
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },

    colorSelected(color) {
      this.$set(this.value, "customColor", color.hexa);
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.deliverable-item {
  overflow: hidden;

  min-width: 320px;
  padding-left: 40px;

  height: 50px;

  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: red;
    margin-right: 5px;
  }

  .deliverable-title {
    font-size: 16px;
  }

  .deliverable-task-content {
    height: 100%;
    .form-col {
      min-width: 200px;
      padding-top: 27px;
      padding-right: 26px;
    }
  }

  .brief {
    color: #cfd8dc !important;
  }

  .deliverable-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    top: 22px;
  }
}
</style>