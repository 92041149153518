<template>
  <v-card
    :loading="loading"
    class="service-track bg-grey-gradient ma-0 pa-0"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >

    <!-- <div
        class="service-status"
        :class="{
          primary: value.status.code === 'PUBLISHED',
          'teal darken-1': value.status.code === 'PENDING',
        }"
      >
        {{ value.status.name }}
      </div> -->
    <v-card-text class="py-0">
      <v-row>
        <!-- <v-col cols="12">{{ value.code }} {{ value.name }}</v-col> -->
        <v-col
          ><v-icon color="white">mdi-clock-outline</v-icon> {{ duration }};
          {{ durationString }}</v-col
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>
    
    <script>
import { FormatterHelper } from "../../../../../../components/helpers/formatter.helper";
import moment from "moment";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      cutFormatter: FormatterHelper.cutString,
      loading: false,
      show: false,
    };
  },
  computed: {
    duration: function () {
      return `${(moment(this.to).diff(moment(this.from), "days")  || 0 )} day(s)`;
    },
    durationString: function () {
      return `${moment(this.from).format("MMM Do, ddd HH:00")} -   ${moment(this.to).format(
        "MMM Do, ddd HH:00"
      )} `;
    },
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },

    getImageStyle() {
      return {
        background: `url(` + this.value.type.image + ")",
        "background-size": "1200px 1200px",
        "background-position": "50% 50%",
        "background-repeat": "no-repeat",
      };
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.service-track {
  overflow: hidden;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .service-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .service-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .service-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    top: 22px;
  }
}
</style>