<template>
  <div>
    <span> {{ spaces + " " + value.label }}</span>
    <ul
      v-if="value.tasks && value.tasks.length"
      class="gantt-tasks-list"
      :style="{}"
    >
      <li
        class="gantt-tasks-item"
        v-for="task in value.tasks"
        :key="task.id"
        :id="`vue-html-gantt-task-${task.id}`"
      >
        <VueHTMLGanttRecursiveTask
          :value="task"
          :deep="deep + 1"
        ></VueHTMLGanttRecursiveTask>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  name: "VueHTMLGanttRecursiveTask",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    deep: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    spaces: function () {
      return new Array(this.deep).fill("-").join();
    },
  },
};
</script>


<style lang="scss" scoped>
.gantt-tasks-list {
  list-style: none;
  padding-left: 0px;
  min-width: 60px;

  .gantt-name {
    height: 85px;
    border-bottom: 1px solid;
  }

  .gantt-tasks-item {
    min-height: 40px;

    margin-bottom: 4px;
    margin-top: 4px;
    width: 100%;
    &:nth-child(1) {
      border-top: 1px solid;
    }

    &:nth-last-child(even) {
      border-bottom: 1px solid;
    }
  }
}
</style>