<template>
  <v-card
    :loading="loading"
    class="service-item bg-grey-gradient"
    dark
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div class="service-type-img control" :style="getImageStyle()"></div>

    <!-- <div
      class="service-status"
      :class="{
        primary: value.status.code === 'PUBLISHED',
        'teal darken-1': value.status.code === 'PENDING',
      }"
    >
      {{ value.status.name }}
    </div> -->
    <v-card-title class="py-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ value.code }} {{ cutFormatter(value.name, 20) }}
          </span>
        </template>
        <span>{{ value.name }}</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-btn icon color="error" @click="$emit('onRemove', value)"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="service-task-content pa-0">
      <v-row>
        <v-col class="form-col flex-grow-1 flex-shrink-1">
          <SelectorDefault
            dark
            v-model="value.selectedPlan"
            label="Plan Size"
            :items="value.plans"
            :helpText="'Please specify a plan that uses for the proposal'"
            required
            clearable
            hide-details
            return-object
            @input="$emit('onPlanSelected')"
          ></SelectorDefault
        ></v-col>
      </v-row>
    </v-card-text>

    <!-- <div class="card-actions-bottom">

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="$emit('onRemove', value)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-btn icon @click="$emit('onServiceClick', value)"
            ><v-icon>mdi-share</v-icon></v-btn
          >
        </v-card-actions>
      </div> -->
  </v-card>
</template>
  
  <script>
import { FormatterHelper } from "../../../../../../components/helpers/formatter.helper";
import SelectorDefault from "../../../../../../components/wad/atoms/common/autocompletes/SelectorDefault.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      formatter: FormatterHelper,
      cutFormatter: FormatterHelper.cutString,
      loading: false,
      show: false,
    };
  },
  components: {
    SelectorDefault,
  },
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },

    getImageStyle() {
      return {
        background: `url(` + this.value.type.image + ")",
        "background-size": "1200px 1200px",
        "background-position": "50% 50%",
        "background-repeat": "no-repeat",
      };
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.service-item {
  overflow: hidden;

  min-width: 320px;
  height: 110px;
  .service-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .service-task-content {
    height: 100%;
    .form-col {
      min-width: 200px;
      padding-top: 14px;
      padding-right: 26px;
    }
  }

  .brief {
    color: #cfd8dc !important;
  }

  .service-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    text-transform: uppercase;
    text-align: center;
    top: 22px;
  }
}
</style>