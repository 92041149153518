<template>
  <div class="vue-html-gantt" id="vue-html-gantt" v-bind="$attrs">
    <!-- Left Tasks -->
    <div class="gantt-tasks-container" id="vue-html-gantt-tasks-container">
      <ul class="gantt-tasks-list" :style="{}">
        <li class="gantt-name">
          <span class="name-title">
            {{ name }}
          </span>

          <v-btn
            @click="PrintDiv"
            icon
            :loading="download"
            :disabled="download"
          >
            <v-icon id="download">mdi-download</v-icon>
          </v-btn>

          <!-- <v-btn
            @click="collapsed = !collapsed"
            icon
            :loading="download"
            :disabled="download"
            v-if="collapsed"
          >
            <v-icon id="download">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            @click="collapsed = !collapsed"
            icon
            :loading="download"
            :disabled="download"
            v-else
          >
            <v-icon id="download">mdi-chevron-right</v-icon>
          </v-btn> -->
        </li>

        <slot
          v-if="hasSlot('tasks')"
          v-bind:tasks="pTasks"
          v-bind:id="(id) => `vue-html-gantt-task-${id}`"
          name="tasks"
        ></slot>

        <li
          v-else
          class="gantt-tasks-item"
          v-for="(task, i) in pTasks"
          :key="task.id"
          :id="`vue-html-gantt-task-${task.id}`"
        >
          <slot
            v-if="hasSlot('task')"
            v-bind:task="task"
            v-bind:index="i"
            name="task"
          ></slot>
          <div v-else>
            <VueHTMLGanttRecursiveTaskVue
              :value="task"
              :deep="0"
            ></VueHTMLGanttRecursiveTaskVue>
          </div>
        </li>
      </ul>
    </div>
    <div
      id="gantt-headers-row"
      class="gantt-headers-row"
      @scroll="onGanttHorizontalScroll"
    >
      <div
        class="gantt-header-col"
        v-for="(header, i) in headers"
        :key="header.date"
        :id="`gantt-header-col-${header.date}`"
        :class="{
          today: isToday(header.date),
          weekend: isWeekend(header.date),
        }"
      >
        <!-- Main Header -->
        <slot
          v-if="hasSlot('header')"
          v-bind:header="header"
          v-bind:index="i"
          name="header"
        ></slot>
        <div class="header-title" v-else>
          <span>
            {{ formatHeader(header) }}
          </span>
        </div>
      </div>

      <!-- Tracks -->
      <div
        class="tracks-container"
        @mousemove="(e) => onTrackMouseMove(e)"
        @mouseleave="(e) => onTrackMouseLeave(e)"
        @touchmove="(e) => onTrackTouchMove(e)"
        @mouseup="(e) => onTrackMouseUp(e)"
      >
        <div
          class="track-item"
          :style="track.style"
          v-for="(track, i) in pTracks"
          :key="track.id"
          @mousedown="(e) => onTrackMouseDown(e, track)"
          @mouseup="(e) => onTrackMouseUp(e, track)"
          @touchstart="(e) => onTrackMouseDown(e, track)"
        >
          <template v-if="hasSlot('track')">
            <slot v-bind:track="track" v-bind:index="i" name="track"></slot>
            <div
              class="increase-left"
              @mousedown="(e) => onIncreaseLeftMouseDown(e, track)"
            >
              <div class="dots"></div>
            </div>
            <div
              class="increase-right"
              @mousedown="(e) => onIncreaseRightMouseDown(e, track)"
            >
              <div class="dots"></div>
            </div>
          </template>

          <div class="default-track" v-else>
            <div
              class="increase-left arrow left"
              @mousedown="(e) => onIncreaseLeftMouseDown(e, track)"
            ></div>

            <div class="content">
              <span>
                {{ track.label }}
              </span>
            </div>

            <div
              class="increase-right arrow right"
              @mousedown="(e) => onIncreaseRightMouseDown(e, track)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import moment from "moment";
import VueHTMLGanttRecursiveTaskVue from "./VueHTMLGanttRecursiveTask.vue";
import html2canvas from "html2canvas";

export default {
  name: "VueHTMLGantt",
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    from: {
      type: String,
      default: new Date().toISOString(),
    },
    to: {
      type: String,
      default: new Date().toISOString(),
    },

    min: {
      type: Number,
      default: undefined,
    },
    name: {
      type: String,
    },
    zoomLevel: {
      type: String,
      default: "day", // can be 'day' |'hour' | 'month'
    },
    taskKey: {
      type: String,
      default: "id",
    },
    taskLabel: {
      type: String,
      default: "name",
    },
    trackKey: {
      type: String,
      default: "id",
    },
    trackLabel: {
      type: String,
      default: "name",
    },
    weekends: {
      type: Array,
      default: () => ["Saturday", "Sunday"],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueHTMLGanttRecursiveTaskVue,
  },
  data() {
    return {
      headers: this.generateHeaders(this.from, this.to, this.zoomLevel),
      pTasks: this.prepareTasks(this.value),
      pTracks: this.prepareTracks(this.value),

      previousTouch: undefined,
      increase: undefined,
      selectedTrack: undefined,
      offsetI: 0,
      parentUpdateTimeout: undefined,
      scrollLeft: 0,

      download: false,
    };
  },
  mounted() {
    this.pTasks = this.prepareTasks(this.value);
    this.pTracks = this.prepareTracks(this.value);
  },
  methods: {
    onTrackMouseLeave() {
      console.log("onTrackMouseLeave");

      if (!this.selectedTrack || !this.editable) return;

      this.$set(this.selectedTrack.style, "-webkit-user-select", undefined);
      this.$set(this.selectedTrack.style, "-ms-user-select", undefined);
      this.$set(this.selectedTrack.style, "user-select", undefined);
      this.$set(this.selectedTrack.style, "cursor", undefined);

      this.selectedTrack = undefined;
      this.increase = undefined;
    },
    onTrackMouseUp() {
      console.log("onTrackMouseUp");

      if (!this.selectedTrack || !this.editable) return;

      this.$set(this.selectedTrack.style, "-webkit-user-select", undefined);
      this.$set(this.selectedTrack.style, "-ms-user-select", undefined);
      this.$set(this.selectedTrack.style, "user-select", undefined);
      this.$set(this.selectedTrack.style, "cursor", undefined);

      this.selectedTrack = undefined;
      this.increase = undefined;
    },
    onTrackMouseDown(e, track) {
      console.log("onTrackMouseDown");
      if (!this.editable) return;

      this.selectedTrack = track;

      this.$set(this.selectedTrack.style, "-webkit-user-select", "none");
      this.$set(this.selectedTrack.style, "-ms-user-select", "none");
      this.$set(this.selectedTrack.style, "user-select", "none");
      this.$set(this.selectedTrack.style, "cursor", "grabbing");
    },

    onIncreaseLeftMouseDown(e) {
      if (!this.editable) return;

      e.preventDefault();
      this.increase = "left";
    },
    onIncreaseRightMouseDown(e) {
      if (!this.editable) return;

      e.preventDefault();
      this.increase = "right";
    },
    onTrackTouchMove(e) {
      //   console.log("onTrackTouchMove");

      if (!this.selectedTrack || !this.editable) return;

      const touch = e.touches[0];

      if (this.previousTouch) {
        // be aware that these only store the movement of the first touch in the touches array
        const movementX = touch.pageX - this.previousTouch.pageX;
        // e.movementY = touch.pageY - this.previousTouch.pageY;
        const elementX = parseInt(this.selectedTrack.style.left);

        this.selectedTrack.style.left = elementX + movementX + "px";
      }

      this.previousTouch = touch;
    },
    onTrackMouseMove(e) {
      if (!this.selectedTrack || !this.editable) return;

      const { movementX } = e;

      const elementX = parseInt(this.selectedTrack.style.left);
      const oldWidth = parseInt(this.selectedTrack.style.width);

      const fromId = moment(this.selectedTrack.from)
        .startOf(this.zoomLevel)
        .toISOString();

      const toId = moment(this.selectedTrack.to)
        .startOf(this.zoomLevel)
        .toISOString();

      const fromCol = document.getElementById(`gantt-header-col-${fromId}`);
      const toCol = document.getElementById(`gantt-header-col-${toId}`);

      switch (this.increase) {
        case "right": {
          const newWidth = oldWidth + movementX;

          if (fromCol || toCol) {
            const { width } = fromCol
              ? fromCol.getBoundingClientRect()
              : toCol.getBoundingClientRect();
            const hourWidth = width / 24;

            // const adjustedMovementX =  movementX%hourWidth

            if (this.min && hourWidth * this.min > newWidth) return;

            this.selectedTrack.to = moment(this.selectedTrack.to)
              .add(movementX / hourWidth, "hours")
              .toISOString();
          }

          this.selectedTrack.style.width = newWidth + "px";

          break;
        }
        case "left": {
          const newWidth = oldWidth - movementX;

          if (fromCol || toCol) {
            const { width } = fromCol
              ? fromCol.getBoundingClientRect()
              : toCol.getBoundingClientRect();
            const hourWidth = width / 24;
            const minutesWidth = width / 24 / 60;

            if (this.min && hourWidth * this.min > newWidth) return;

            console.log(
              "MM15",
              Math.abs(
                (Math.round((newWidth - oldWidth) / minutesWidth / 30) * 30) %
                  60
              )
            );
            // console.log('M', Math.round((newWidth - oldWidth) / hourWidth  ) )

            this.selectedTrack.from = moment(this.selectedTrack.from)
              .subtract(
                Math.round((newWidth - oldWidth) / minutesWidth / 30) * 30,
                "minutes"
              )
              .set("seconds", 0)
              .toISOString();
          }

          this.selectedTrack.style.width = newWidth + "px";
          this.selectedTrack.style.left = elementX + movementX + "px";
          break;
        }

        default: {
          if (fromCol || toCol) {
            const { width } = fromCol
              ? fromCol.getBoundingClientRect()
              : toCol.getBoundingClientRect();

            const hourWidth = width / 24;

            this.selectedTrack.from = moment(this.selectedTrack.from)
              .add(movementX / hourWidth, "hours")
              .toISOString();
            this.selectedTrack.to = moment(this.selectedTrack.to)
              .add(movementX / hourWidth, "hours")
              .toISOString();
          }

          this.selectedTrack.style.left = elementX + movementX + "px";
          break;
        }
      }

      if (this.parentUpdateTimeout) clearTimeout(this.parentUpdateTimeout);

      this.parentUpdateTimeout = ((item, from, to) => {
        const selectedTrack = item;
        return setTimeout(() => {
          selectedTrack.origin.from = from;
          selectedTrack.origin.to = to;
          this.$emit("input", this.value);
        }, 100);
      })(this.selectedTrack, this.selectedTrack.from, this.selectedTrack.to);

      //   this.selectedTrack.origin.from = this.selectedTrack.from;
      //   this.selectedTrack.origin.to = this.selectedTrack.to;

      this.previousTouch = e;
    },
    onGanttHorizontalScroll({
      target: { scrollLeft, clientWidth, scrollWidth },
    }) {
      // { target: { scrollTop, scrollLeft, clientHeight, scrollHeight, scrollWidth } }

      const percent = 0.9 - clientWidth / scrollWidth;

      const percentValue = Math.round(percent * scrollWidth);

      if (scrollLeft >= percentValue) this.offsetI += 1;
      this.headers = this.generateHeaders(
        this.from,

        moment(this.to)
          .add(30 * this.offsetI, "days")
          .toISOString(),
        this.zoomLevel
      );

      this.scrollLeft = scrollLeft;
    },
    // ======================== Helper Functions ========================
    isToday(date) {
      return moment()
        .startOf(this.zoomLevel)
        .isSame(moment(date).startOf(this.zoomLevel));
    },
    isWeekend(date) {
      return this.weekends.reduce((acc, curr) => {
        return (acc |=
          moment(date)
            .startOf(this.zoomLevel)
            .format("dddd")
            .trim()
            .toLowerCase() === String(curr).trim().toLowerCase());
      }, 0);
    },
    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
    prepareTracks(tasks = []) {
      const output = tasks.reduce(
        (acc, task, i) => this.recursiveParseTracks(task, i, acc),
        []
      );

      return output;
    },
    recursiveParseTracks(task, taskIndex, acc = []) {
      const id = task[this.taskKey] ? task[this.taskKey] : taskIndex;

      if (task.tracks && task.tracks.length) {
        const tracks = task.tracks.map((track, tI) =>
          this.prepareTrack(track, tI, id)
        );

        acc = acc.concat(...tracks);
      }

      if (task.tasks && task.tasks.length) {
        const tracks = task.tasks.reduce(
          (acc2, subTask, i) => this.recursiveParseTracks(subTask, i, acc2),
          []
        );
        return acc.concat(...tracks);
      } else return acc;
    },
    prepareTrack(track, trackIndex, parentId) {
      let width = 10;
      let x = 0;
      let y = 0;
      let height = 10;
      const tId = track[this.trackKey] ? track[this.trackKey] : trackIndex;

      const existingTrack =
        this.pTracks && this.pTracks.length
          ? this.pTracks.find((t) => t.id === tId)
          : undefined;

      const startId = moment(track.from).startOf(this.zoomLevel).toISOString();
      const endId = moment(track.to).startOf(this.zoomLevel).toISOString();

      const parentElement = document.getElementById("vue-html-gantt");
      const taskElement = document.getElementById(
        `vue-html-gantt-task-${parentId}`
      );

      const startCol = document.getElementById(`gantt-header-col-${startId}`);
      const endCol = document.getElementById(`gantt-header-col-${endId}`);

      if (startCol && endCol && parentElement && taskElement) {
        const startPositions = startCol.getBoundingClientRect();
        const endPositions = endCol.getBoundingClientRect();

        const taskPositions = taskElement.getBoundingClientRect();
        const parentPositions = parentElement.getBoundingClientRect();

        y = taskPositions.y - parentPositions.y - 88; // headers height

        height = taskPositions.height + 2;

        // for X we also need to set a proper value that will describe other time as well;
        // TODO NOW it works only for HOURS. ADD MORE LOGIC to handle other units
        const widthPerHour = startPositions.width / 24;

        const diffFrom = moment(track.from).diff(
          moment(track.from).startOf(this.zoomLevel),
          "hour"
        );
        const diffTo = moment(track.to).diff(
          moment(track.to).startOf(this.zoomLevel),
          "hour"
        );

        x =
          startPositions.x -
          parentPositions.x -
          taskPositions.width -
          1 +
          this.scrollLeft +
          diffFrom * widthPerHour;
        width =
          endPositions.x -
          startPositions.x -
          1 -
          diffFrom * widthPerHour +
          diffTo * widthPerHour;

        if (this.min && width < widthPerHour * this.min)
          width = widthPerHour * this.min;
      }

      if (existingTrack) {
        existingTrack.from = track.from;
        existingTrack.to = track.to;
        existingTrack.origin = track;
        existingTrack.style = {
          ...track.style,
          position: "absolute",
          top: y + "px",
          left: x + "px",
          height: height + "px",
          width: width + "px",
        };
      }
      return existingTrack
        ? existingTrack
        : {
            id: tId,
            parentId,
            label: track[this.trackLabel]
              ? track[this.trackLabel]
              : "trackLabel was not provided",
            origin: track,
            from: track.from,
            to: track.to,
            style: {
              ...track.style,
              position: "absolute",
              top: y + "px",
              left: x + "px",
              height: height + "px",
              width: width + "px",
            },
          };
    },

    prepareTasks(tasks) {
      const output = tasks.map((task, i) => {
        const id = task[this.taskKey] ? task[this.taskKey] : i;

        return {
          id,
          label: task[this.taskLabel]
            ? task[this.taskLabel]
            : "taskLabel was not provided",
          tasks:
            task.tasks && task.tasks.length
              ? this.prepareTasks(task.tasks)
              : undefined,
          origin: task,
        };
      });

      return output;
    },
    generateHeaders(from, to, zoomLevel) {
      const headers = new Array(moment(to).diff(moment(from), zoomLevel))
        .fill(undefined)
        .map((el, i) => ({
          id: i,
          date: moment(from).add(i, zoomLevel).startOf(zoomLevel).toISOString(),
        }));
      return headers;
    },
    formatHeader(value) {
      switch (this.zoomLevel) {
        case "day":
          return moment(value.date).format("MMM Do, ddd");
        case "hour":
          return moment(value.date).format("LT");

        case "month":
          return moment(value.date).format("LL");

        default:
          break;
      }
    },
    async PrintDiv() {
      this.download = true;

      const timeline = document.getElementById("vue-html-gantt");

      const containerScroll = document.getElementById("gantt-headers-row");

      timeline.style.width = `${containerScroll.scrollWidth}px`;
      timeline.style.height = `${containerScroll.scrollHeight}px`;

      const canvas = await html2canvas(timeline, {
        backgroundColor: null,
        allowTaint: true,
        ignoreElements: (el) => el.id === "download",
        scale: 2,
      });
      timeline.style.width = "";
      timeline.style.height = "";

      const img = canvas.toDataURL();
      this.downloadURI(img, (this.name || "MyTimeline") + ".png");

      this.download = false;
    },
    downloadURI(uri, name) {
      const link = document.createElement("a");

      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();

      //after creating link you should delete dynamic link
      //clearDynamicLink(link);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.pTasks = this.prepareTasks(newVal);
        this.$nextTick(() => {
          this.pTracks = this.prepareTracks(newVal);
        });
      },
      deep: true,
    },
    from: {
      handler(newVal) {
        this.headers = this.generateHeaders(newVal, this.to, this.zoomLevel);

        this.pTasks = this.prepareTasks(this.value);
        this.$nextTick(() => {
          this.pTracks = this.prepareTracks(this.value);
        });
      },
      deep: true,
    },
    to: {
      handler(newVal) {
        this.headers = this.generateHeaders(this.from, newVal, this.zoomLevel);

        this.pTasks = this.prepareTasks(this.value);
        this.$nextTick(() => {
          this.pTracks = this.prepareTracks(this.value);
        });
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.vue-html-gantt {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;

  .gantt-tasks-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid;

    .gantt-tasks-list {
      list-style: none;
      padding-left: 0px;
      min-width: 80px;

      .gantt-name {
        height: 85px;
        border-bottom: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        .name-title {
          /* transform: rotate(-12deg); */
          max-width: 221px;
          text-align: center;
          font-size: 16px;
          /* background: rgba(255,255,255,0.1); */
          padding: 7px;
        }
      }

      .gantt-tasks-item {
        min-height: 40px;

        margin-bottom: 4px;
        margin-top: 4px;
        width: 100%;
        border-bottom: 1px solid;

        // &:nth-child(1) {
        //   border-top: 1px solid;
        // }
      }
    }
  }

  .gantt-headers-row {
    display: flex;
    flex-direction: row;
    position: relative;
    // height: 100%;

    overflow-x: auto;

    .gantt-header-col {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 50px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      min-width: 50px;

      &.today {
        background-color: rgba($color: #5ea9ca, $alpha: 0.4) !important;
      }

      &.weekend {
        background-color: rgba($color: #cd303b, $alpha: 0.3) !important;
      }

      &:nth-child(2n + 1) {
        background: rgba(255, 255, 255, 0.2);
      }

      .header-title {
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid white;
        height: 85px;
        span {
          text-align: center;
        }
      }
    }

    .tracks-container {
      //   z-index: -1;
      position: absolute;
      width: 100%;
      height: calc(100% - 85px);
      left: 0;
      top: 85px;

      .track-item {
        z-index: 2;
        cursor: grab;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .dots {
          width: 4px;
          height: 12px;
          position: relative;
        }

        .dots:before,
        .dots:after {
          content: "";
          width: 100%;
          height: 4px;
          border-radius: 2px;
          position: absolute;
          left: 0;
          background-color: black;
        }

        .dots:before {
          top: 0;
        }

        .dots:after {
          bottom: 0;
        }

        .increase-left {
          cursor: pointer;
          position: absolute;
          left: 6px;

          &:before {
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.2s;
            background: rgba($color: #000000, $alpha: 0.3);
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        .increase-right {
          cursor: pointer;
          position: absolute;
          right: 6px;

          &:before {
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.2s;
            background: rgba($color: #000000, $alpha: 0.3);
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        .default-track {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .arrow {
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
          }

          .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          .left {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
      }
    }
  }
}
</style>